<template>
  <div>
    <b-card>
      <div slot="header">
        <strong>주문통계 <small>배송보드 주문중 교환, 취소, 반품을 제외한 결제된 주문입니다. 주문일(결제이후) 기준입니다.</small> </strong>
      </div>
      <b-form>
        <div><small>주문일</small></div>
        <date-from-to :from.sync="form.orderDateFrom" :to.sync="form.orderDateTo" v-bind="{twoDays: true, absMonth: 12, year: 5}" @enter="list()">
        </date-from-to>

        <div class="mb-2"></div>

        <b-collapse id="collapse">
          <shop-preset class="mb-2" v-model="form.shop"></shop-preset>
          <b-row>
            <b-col>
              <brand-preset class="mb-2" v-model="form.brand" :hideDisabled="true"></brand-preset>
            </b-col>
            <b-col>
              <category-preset class="mb-2" v-model="form.category"></category-preset>
              <b-form-checkbox v-model="form.exactCategory">하위카테고리를 포함하지 않습니다</b-form-checkbox>
            </b-col>
          </b-row>

<!--          <div class="mb-2">-->
<!--            <small>주문범위</small>-->
<!--            <b-form-checkbox v-model="form.includeCancel">교환, 취소, 반품도 포함합니다</b-form-checkbox>-->
<!--          </div>-->

          <b-row class="mb-2">
            <b-col md="3">
              <div><small class="mb-n2">연동형태</small></div>
              <b-form-radio-group class="col-form-label" v-model="form.manual" :options="[
                {text: '전체', value: 'ALL'},
                {text: '자동(FEED)', value: 'auto'},
                {text: '파트너관리', value: 'manual'}
              ]"></b-form-radio-group>
            </b-col>
            <b-col md="6">
              <div><small class="mb-n2">상품유형</small></div>
              <b-form inline>
                <b-form-radio-group class="col-form-label" v-model="form.goodsType" :options="[
                  {text: '전체', value: 'ALL'},
                  {text: '새상품만', value: 'new'},
                  {text: '빈티지만', value: 'used'}
                ]"></b-form-radio-group>
                <template v-if="form.goodsType === 'used'">
                  <b-button class="mr-1" size="sm" variant="light" @click="toggleUsedGrade()">전체</b-button>
                  <b-button class="mr-1" size="sm" variant="primary" @click="toggleUsedGrade('S')">S</b-button>
                  <b-button class="mr-1" size="sm" variant="success" @click="toggleUsedGrade('A')">A</b-button>
                  <b-button class="mr-2" size="sm" variant="warning" @click="toggleUsedGrade('B')">B</b-button>
                  <b-form-checkbox-group v-model="form.usedGrade">
                    <b-form-checkbox v-for="s in $C.USED_GRADE" :key="s.value" :value="s.value" :title="s.desc">{{ s.text }}</b-form-checkbox>
                  </b-form-checkbox-group>
                </template>
              </b-form>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col md="3">
              <small>goods_no</small>
              <b-form-textarea :rows="2" v-model="form.goods_no_include" placeholder="goods_no를 입력해주세요"></b-form-textarea>
            </b-col>
            <b-col md="3">
              <small>제외할 goods_no</small>
              <b-form-textarea :rows="2" v-model="form.goods_no_exclude" placeholder="제외할 goods_no를 입력해주세요"></b-form-textarea>
            </b-col>
          </b-row>

          <b-button class="mr-1 mb-1" size="sm" variant="primary" @click="toggleMall">전체 선택</b-button>
          <b-form-checkbox-group id="mall" name="selectedMall" v-model="form.mall">
            <b-form-checkbox v-for="s in $C.MALL" :key="s.name" :value="s.name">{{ s.name }}</b-form-checkbox>
          </b-form-checkbox-group>
          <div class="mb-2"></div>
        </b-collapse>

        <b-row class="mb-2">
          <b-col cols="12" lg="2">
            <div><small>정렬순서</small></div>
            <b-form-radio-group class="col-form-label" v-model="form.orderBy" :options="[
                {text: '판매금액', value: 'amount'},
                {text: '거래액', value: 'sale_price'},
                {text: '실거래액', value: 'real_sale_price'},
                {text: '최대혜택주문액', value: 'member_price'},
                {text: '판매수량', value: 'cnt'},
                {text: '주문수', value: 'cnt'},
                {text: '실주문수', value: 'real_cnt'},
              ]"></b-form-radio-group>
          </b-col>
          <b-col cols="12" lg="2">
            <b-row>
              <b-col>
                <div><small>Top 그룹수</small></div>
                <b-input v-model.number="form.groupLimit"></b-input>
              </b-col>
              <b-col>
                <div><small>Sub 그룹수</small></div>
                <b-input v-model.number="form.subGroupLimit"></b-input>
              </b-col>
              <b-col>
                <div><small>Top 상품수</small></div>
                <b-input v-model.number="form.goodsLimit"></b-input>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" lg="5">
            <div><small>그룹순서(드래그 해서 지정해주세요)</small></div>
            <draggable v-model="form.groupBy" @start="drag=true" @end="drag=false" @change="">
              <template v-for="e in form.groupBy">
                <b-button :variant="groupColorMap[e]" class="btn-pill m-1">{{ e === 'category' ? groupNameMap[form.categoryType] : groupNameMap[e] }}</b-button>
              </template>
            </draggable>
            <b-form-checkbox v-model="form.allGroup">최상위 그룹을 전체 주문 대상으로 합니다</b-form-checkbox>
          </b-col>
          <b-col cols="12" lg="3">
            <div><small>카테고리 종류</small></div>
            <b-form-radio-group class="col-form-label" v-model="form.categoryType" @change="setCategoryType" :options="[
              {text: '1차 카테고리', value: 'genderCategory'},
              {text: '2차 카테고리', value: 'majorCategory'},
              {text: '3차 카테고리', value: 'minorCategory'},
              {text: '4차 카테고리', value: 'smallerCategory'},
              {text: '최종 카테고리(3~5차)', value: 'category'},
            ]"></b-form-radio-group>
          </b-col>
        </b-row>
      </b-form>
      <b-button class="m-1" variant="primary" @click="list">조회</b-button>
      <b-button class="m-1" variant="outline-success" v-b-toggle.collapse>상세검색조건</b-button>
    </b-card>

    <div class="d-flex align-items-center">
      <div class="ml-auto">
        <b-button class="m-1" variant="success" @click="downGroup('xlsx')">그룹 XLSX</b-button>
        <b-button class="m-1" variant="success" @click="down('xlsx')">개별 XLSX</b-button>
        <b-button class="m-1" variant="success" @click="down('txt')">개별Text</b-button>
      </div>
    </div>

    <b-card>
      <line-chart ref="lineChart" :chartdata="chartData" :options="chartOptions" style="height:200px"/>
    </b-card>

    <b-card v-for="(A,i) in items">
      <h4 v-if="form.idxGroup === 0">
        <span :title="A.id" class="pointer" @click="$utils.copyAlert(A.id)">
          {{ groupNameMap[lastForm.groupBy[0 - form.idxGroup]] }} {{ i + 1 }}위 : {{ A.name }}
        </span>
      </h4>
      <h4 v-else>{{ A.name }}</h4>
      <b-row>
        <b-col cols="1">
          <b-badge variant="success">판매금액</b-badge>
          {{ $utils.comma(A.amount) }}<br/>
          <b-badge variant="primary">판매수량</b-badge>
          {{ $utils.comma(A.qty) }}
        </b-col>
        <b-col cols="1">
          <b-badge variant="success">거래액</b-badge>
          {{ $utils.comma(A.sale_price) }}<br/>
          <b-badge variant="primary">주문수</b-badge>
          {{ $utils.comma(A.cnt) }}
        </b-col>
        <b-col cols="2">
          <b-badge variant="success">실거래액</b-badge>
          {{ $utils.comma(A.real_sale_price) }}<br/>
          <b-badge variant="primary">최대혜택주문액</b-badge>
          {{ $utils.comma(A.member_price) }}<br/>
          <b-badge variant="primary">실주문수</b-badge>
          {{ $utils.comma(A.real_cnt) }}
        </b-col>
      </b-row>

      <hr/>
      <h5>Top {{form.subGroupLimit}} {{ groupNameMap[lastForm.groupBy[1 - form.idxGroup]] }}</h5>
      <b-row class="mt-3">
        <b-col cols="2" v-for="(B, j) in A.groupB.slice(0, form.subGroupLimit)" :key="j">
          <h6>
            <span :title="B.id" class="pointer" @click="$utils.copyAlert(B.id)">{{ B.name }}</span>
          </h6>
          <b-badge variant="success">(OLD)판매금액</b-badge>
          {{ $utils.comma(A.amount) }}<br/>
          <b-badge variant="primary">(OLD)판매수량</b-badge>
          {{ $utils.comma(A.qty) }}<br/>
          <b-badge variant="success">거래액</b-badge>
          {{ $utils.comma(B.sale_price) }}<br/>
          <b-badge variant="primary">주문수</b-badge>
          {{ $utils.comma(B.cnt) }}<br/>
          <b-badge variant="success">실거래액</b-badge>
          {{ $utils.comma(A.real_sale_price) }}<br/>
          <b-badge variant="primary">최대혜택주문액</b-badge>
          {{ $utils.comma(A.member_price) }}<br/>
          <b-badge variant="primary">실주문수</b-badge>
          {{ $utils.comma(A.real_cnt) }}<br/>
          <b-badge variant="dark">주요 {{ groupNameMap[lastForm.groupBy[2 - form.idxGroup]] }}</b-badge>
          <br/>
          <template v-if="B.groupC">
            <b-badge class="mb-1 mr-1 pointer" variant="secondary" :title="C.id" v-for="(C, k) in B.groupC.slice(0, 5)" @click="$utils.copyAlert(C.id)" :key="k">{{ C.name }}</b-badge>
          </template>
        </b-col>
      </b-row>
      <hr/>
      <h5>Top {{ form.goodsLimit }} 상품</h5>
      <b-row v-if="form.goodsLimit" class="mt-3">
        <b-col>
          <div class="d-inline-block mr-1 text-center" v-for="e in A.goods">
            <img :src="e.img" style="height:105px" :title="e.goods_nm"/><br/>
            <a :href="`/#/goods/${e._id}`" target="_blank" class="badge badge-primary">{{ e._id }}</a><br/>
            <template v-if="lastForm.groupBy[1-form.idxGroup] === 'shop_id'">
              <b-badge variant="success">{{ e.shop_id }}. {{ shopMap[e.shop_id] ? shopMap[e.shop_id].boutique : "SHOP 정보 없음" }}</b-badge>
              <br/>
            </template>
            <template v-if="lastForm.groupBy[1-form.idxGroup] === 'brand_no'">
              <b-badge variant="warning">{{ brandMap[e.brand_no] ? brandMap[e.brand_no].brand_nm : `brand_no : ${e.brand_no}` }}</b-badge>
              <br/>
            </template>
            <b-badge variant="light">단가: {{ $utils.comma(e.price) }} 원</b-badge>
            <br/>
            <b-badge variant="light">{{ $utils.comma(e.top_qty) }} 개</b-badge>
            <br/>
            <b-badge variant="light" :title="`개당 가격 ${$utils.comma(e.price)} 원`">{{ $utils.comma(e.top_amount) }} 원</b-badge>
            <br/>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="text-center" v-if="isBusy">
      <b-spinner variant="primary"></b-spinner>
    </b-card>
    <b-card class="text-center" v-else-if="items.length === 0">
      결과가 없습니다
    </b-card>

  </div>
</template>

<script>
import dateFromTo from '@/views/modules/DateFromTo.vue'
import * as moment from 'moment-timezone';
import draggable from 'vuedraggable'
import {down} from '@/shared/impexp'
import LineChart from '@/views/charts/Line.vue'

export default {
  name: 'OrderStat',
  title: '주문통계',
  components: {dateFromTo, draggable, LineChart},
  data() {
    return {
      months: [],
      shop: [],
      shopMap: {},
      brand: [],
      brandMap: {},
      category: [],
      categoryMap: {},
      form: {
        orderDateFrom: moment().tz("Asia/Seoul").subtract(1, 'week').format('YYYY-MM-DD'),
        orderDateTo: moment().tz("Asia/Seoul").format('YYYY-MM-DD'),
        shop: [],
        category: [],
        brand: [],
        mall: this.$C.MALL.map(e => e.name),
        manual: 'ALL',
        goodsType: 'ALL',
        usedGrade: this.$C.USED_GRADE.map(e => e.value),
        goods_no_include: '',
        goods_no_exclude: '',
        categoryType: 'category',
        groupLimit: 20,
        subGroupLimit: 6,
        goodsLimit: 15,
        orderBy: 'amount',
        groupBy: ['shop_id', 'brand_no', 'category', 'manual', 'goodsType', 'mall'],
        idxGroup: 0,
        exactCategory: false,
        allGroup: false,
        includeCancel: false,
      },
      lastForm: {},
      groupNameMap: {
        brand_no: '브랜드',
        shop_id: '파트너',
        genderCategory: '1차 카테고리',
        majorCategory: '2차 카테고리',
        minorCategory: '3차 카테고리',
        smallerCategory: '4차 카테고리',
        category: '최종 카테고리(3~5차)',
        manual: '연동유형',
        goodsType: '상품유형',
        mall: '판매채널',
      },
      groupColorMap: {
        brand_no: 'warning',
        shop_id: 'success',
        genderCategory: 'secondary',
        majorCategory: 'secondary',
        minorCategory: 'secondary',
        smallerCategory: 'secondary',
        category: 'secondary',
        manual: 'danger',
        goodsType: 'primary',
        mall: 'outline-dark',
      },
      detailCond: {},
      brand_no: '',
      goods_no: '',
      items: [],
      goods: [],
      daily: [],
      chartData: {
        labels: [],
        datasets: [
          {
            label: '일별 매출액',
            backgroundColor: '#f87979',
            data: [],
            fill: false,
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      },

      perPage: 1000,
      isBusy: false,
      isDetailBusy: false,
      modal: false,
    }
  },
  async created() {
    // 마지막 조회조건을 가져온다
    let lastForm = JSON.parse(localStorage.getItem('OrderStat.lastForm'));
    if (lastForm) 'categoryType,groupLimit,subGroupLimit,goodsLimit,orderBy,allGroup'.split(',').forEach(k => {
      if (lastForm[k] != null) this.form[k] = lastForm[k];
    });

    let monSt = moment().startOf('month');
    while (monSt >= moment().add(-12, 'month')) {
      this.months.push(monSt.format('YYYY-MM-DD'));
      monSt.add(-1, 'month');
    }
    let meta = await this.$api.getMeta();
    if (!meta) return;
    this.shop = meta.shop.filter(e => e.use_yn === 'y').sort((a, b) => a.shop_id - b.shop_id);
    meta.shop.forEach(s => {
      s.value = s.boutique;
      s.label = `${s.shop_id}. ${s.boutique}`;
      this.shopMap[s.shop_id] = s;
    });
    this.brand = meta.brand.sort((a, b) => a.brand_nm.localeCompare(b.brand_nm));
    this.brand.forEach(b => {
      b.value = b.brand_no;
      b.label = `${b.brand_nm}(${b.brand_nm_kr})`;
      this.brandMap[b.brand_no] = b;
    });
    this.category = meta.category.sort((a, b) => a.category.localeCompare(b.category));
    this.category.forEach(c => {
      this.categoryMap[c.category] = c;
    });

    this.list();
  },
  methods: {
    setCategoryType(type) {
      this.form.groupBy.forEach((e, i) => {
        if (~e.indexOf('tegory')) {
          this.form.groupBy[i] = type;
        }
      });
    },
    toggleMall() {
      this.form.mall = this.form.mall.length === this.$C.MALL.length ? [] : this.$C.MALL.map(e => e.name);
    },
    toggleUsedGrade(grade) {
      if (!grade) {
        this.form.usedGrade = this.form.usedGrade.length === this.$C.USED_GRADE.length ? [] : this.$C.USED_GRADE.map(e => e.value);
      } else {
        this.form.usedGrade = this.$C.USED_GRADE.filter(e => e.value[0] === grade).map(e => e.value);
      }
    },
    calcIncExc(inc, exc) {
      let include = inc ? inc.trim().split(/\r?\n/g).map(e => e.trim()) : [];
      let exclude = exc ? exc.trim().split(/\r?\n/g).map(e => e.trim()) : [];
      if (include.length && exclude.length) { // 둘 다 존재시 exclude 를 include 에서 제외
        const excludeMap = this.$utils.arr2map(exclude);
        include = include.filter(e => !excludeMap[e]);
        exclude = [];
      }
      return [include, exclude];
    },

    async list() {
      const form = this.form;
      this.lastForm = this.$utils.clone(form);
      localStorage.setItem('OrderStat.lastForm', JSON.stringify(this.lastForm));

      this.items.splice(0, this.items.length);
      const shop = form.shop.length === this.shop.length ? [] : form.shop.map(e => e.shop_id); // 전체 선택일 경우 비우기
      const brand = form.brand.map(e => e.value);
      const category = form.category.map(e => e.value);
      const mall = form.mall.length === this.$C.MALL.length ? [] : form.mall; // 전체 선택일 경우 비우기
      let [goods_no_include, goods_no_exclude] = this.calcIncExc(form.goods_no_include, form.goods_no_exclude);
      goods_no_include = goods_no_include.map(e => e.split(/\D+/g)).flat().map(e => +e); // 123,134 로 한 줄에 들어오는 숫자 특별처리
      goods_no_exclude = goods_no_exclude.map(e => e.split(/\D+/g)).flat().map(e => +e); // 123,134 로 한 줄에 들어오는 숫자 특별처리

      this.isBusy = true;
      let j = await this.$api.postJson('/order/orderStat', {form: {...this.form, shop, brand, category, mall, goods_no_include, goods_no_exclude}});
      this.isBusy = false;
      if (!j) return;
      let dailyMap = {};
      j.list.forEach(e => { // xls 다운로드용 데이터를 정비한다.
        e['판매일'] = e.date;
        e['판매금액'] = e.amount;
        e['판매수량'] = e.qty;
        e['거래액'] = e.sale_price;
        e['실거래액'] = e.real_sale_price;
        e['최대혜택가기준실거래액'] = e.member_price;
        e['주문수'] = e.cnt;
        e['실주문수'] = e.real_cnt;
        e['상품번호'] = e.goods_no;
        e['상품명'] = e.goods_nm;
        e['브랜드'] = this.brandMap[e.brand_no] ? this.brandMap[e.brand_no].brand_nm : e.brand_no; // brand_no == 0
        e['성별'] = {'009': 'Women', '010': 'Men'}[e.genderCategory] || '없음';
        e['2DEPTH'] = (this.categoryMap[e.majorCategory] || {}).category_nm || '';
        e['3DEPTH'] = (this.categoryMap[e.minorCategory] || {}).category_nm || '';
        if (e.category.length >= 12)
          e['4DEPTH'] = (this.categoryMap[e.smallerCategory] || {}).category_nm || '';
        if (e.category.length > 12)
          e['5DEPTH'] = (this.categoryMap[e.category] || {}).category_nm || '';
        e['Shop ID'] = e.shop_id;
        e['구분'] = this.shopMap[e.shop_id].shop_type;
        e['이미지'] = e.img;

        let d = dailyMap[e.date] = dailyMap[e.date] || {amount: 0, cnt: 0};
        d.amount += e.amount;
        d.qty += e.qty;
      });
      this.chartData.labels = Object.keys(dailyMap).sort();
      this.chartData.datasets[0].data = this.chartData.labels.map(e => dailyMap[e].amount);
      this.$refs.lineChart.render();

      j.list.sort((a, b) => b.date.localeCompare(a.date) || (b.amount - a.amount));
      this.goods = j.list;

      this.form.idxGroup = this.form.allGroup ? 1 : 0;

      let sortKey = this.form.orderBy;
      this.items = Object.values(j.groupMap).sort((a, b) => b[sortKey] - a[sortKey]).slice(0, this.form.groupLimit); // 성능상의 이슈도 있고 해서 Top 100 만 뽑는다.

      this.items.forEach(A => {
        this.setName(A, this.form.idxGroup === 0 ? this.form.groupBy[0] : 'all');
        let groupB = Object.values(A.groupB);
        A.groupB = groupB.sort((a, b) => b[sortKey] - a[sortKey]);
        A.groupB.forEach(B => {
          this.setName(B, this.form.groupBy[1 - this.form.idxGroup]);
          let groupC = Object.values(B.groupC);
          B.groupC = groupC.sort((a, b) => b[sortKey] - a[sortKey]);
          B.groupC.forEach(C => {
            this.setName(C, this.form.groupBy[2 - this.form.idxGroup]);
          });
        });
      })
    },
    setName(obj, type) {
      if (type === 'shop_id') {
        let shop = this.shopMap[obj.id];
        if (shop) obj.name = `${obj.id}. ${shop.boutique}`;
        else obj.name = `${obj.id}. (파트너 정보 없음)`;
      } else if (type === 'brand_no') {
        let brand = this.brandMap[obj.id];
        if (brand) obj.name = `${brand.brand_no}. ${brand.brand_nm}`;
        else obj.name = `brand_no : ${obj.id}`;
      } else if (~['genderCategory', 'majorCategory', 'minorCategory', 'smallerCategory', 'category'].indexOf(type)) {
        let category = this.categoryMap[obj.id];
        if (category) obj.name = Array(obj.id.length / 3).fill(0).map((e, i) => this.categoryMap[obj.id.substring(0, 3 + i * 3)].category_nm).join(' > ');
        else obj.name = `${obj.id}`;
        // obj.name = `${obj.id}`;
      } else if (type === 'goodsType') {
        obj.name = {new: '새상품', used: '빈티지'}[obj.id] || '새상품';
      } else if (type === 'manual') {
        obj.name = {true: '파트너관리'}[obj.id] || '자동연동';
      } else if (type === 'mall') {
        obj.name = obj.id;
      } else if (type === 'all') {
        obj.name = '전체 주문';
      }
    },
    async downGroup(type) {
      let headers = `순위,${this.groupNameMap[this.lastForm.groupBy[0 - this.lastForm.idxGroup]]},ID,판매금액,판매수량,거래액,주문수,실거래액,최대혜택가 기준 실거래액,실주문수,`
        + Array(this.form.subGroupLimit).fill(0).map((e, i) => `${this.groupNameMap[this.lastForm.groupBy[1 - this.lastForm.idxGroup]]} ${i + 1}위,판매금액,판매수량,거래액,주문수,실거래액,최대혜택가 기준 실거래액,실주문수`).join(',');
      let rows = this.items.map((A, i) => {
        let row = [i + 1, A.name, A.id, A.amount, A.qty, A.sale_price, A.cnt, A.real_sale_price, A.member_price, A.real_cnt];
        A.groupB.slice(0, this.form.subGroupLimit).forEach(B => row = row.concat([B.name, B.amount, A.qty, A.sale_price, A.cnt, A.real_sale_price, A.member_price, A.real_cnt]));
        console.log(A);
        return row;
      });
      down(rows, headers.split(','), Array(headers.split(',').length).fill(0).map((e, i) => i + ''),
        `OrderStatGroup_${this.lastForm.orderDateFrom}~${this.lastForm.orderDateTo}`, type);
    },
    async down(type) {
      let cols = '판매일,판매수량,판매금액,주문수,실거래액,최대혜택가기준실거래액,실거래액,상품번호,상품명,brand_no,브랜드,category,성별,2DEPTH,3DEPTH,4DEPTH,5DEPTH,Shop ID,구분,이미지'.split(',');
      down(this.goods, cols, cols,
        `OrderStat_${this.$utils.dt()}`, type);
    },
  }
}
</script>
